/**
 * Site header
 */
.site-header {
  min-height: 56px;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  font-size: 26px;
  font-weight: 300;
  line-height: 56px;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

.site-nav {
  float: right;
  line-height: 56px;

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    .page-link {
      padding: 20px 0;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  padding: $spacing-unit 0;
  font-size: 15px;
  color: $grey-color;
  text-align: center;
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
}

.page-heading {
  font-size: 20px;
}

.post-list {
  margin-left: 0;
  list-style: none;

  .post-link:hover {
    text-decoration: none;
  }

  > li {
    margin-bottom: $spacing-unit * 2;

    &:not(:first-child) {
      border-top: 4px solid $grey-color-light;
      padding-top: $spacing-unit * 2;
    }
  }
}

.post-archives {
  margin-left: 0;
  list-style: none;

  .post-link {
    font-size: 24px;
  }

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  &,
  &:visited {
    color: $text-color;
  }

  &:hover {
    color: $brand-color;
  }
}

.post-continue {
  a {
    padding: 8px 15px;
    text-decoration: none;

    &,
    &:visited {
      color: $grey-color;
      background-color: $grey-color-light;
    }

    &:hover {
      color: white;
      background-color: $brand-color;
    }
  }
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    font-size: 36px;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h1 {
    font-size: 38px;

    @include media-query($on-laptop) {
      font-size: 34px;
    }
  }

  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
}



/**
 * Pagination
 */
.pagination {
  padding: $spacing-unit / 2 0;
  border-top: 1px solid $grey-color-light;
  border-bottom: 1px solid $grey-color-light;
  text-align: center;
  @extend %clearfix;

  a {
    &,
    &:visited {
      color: $grey-color;
    }

    &:hover {
      color: $brand-color;
    }
  }

  .previous {
    float: left;
  }

  .next {
    float: right;
  }
}

/**
 * Fleuron
 */
.fleuron {
  text-align: center;
  margin: $spacing-unit;
}

/**
 * Disqus
 */
.disqus-comment {
  text-align: center;
  padding-top: 15px;
}

.disqus-box {
  a {
    padding: 8px 15px;
    text-decoration: none;

    &,
    &:visited {
      color: $grey-color;
      background-color: $grey-color-light;
    }

    &:hover {
      color: white;
      background-color: $brand-color;
    }
  }
}

/**
 * Staticman
 */
.staticman-comments {
  padding-top: 20px;
}

.staticman-comments .sc-fieldset {
  margin: 0px;
  padding-left: 0px;
  border: none;
}

.staticman-comments .sc-input {
  font-size: $base-font-size;
  border: 1px solid $grey-color-light;
  margin-top: 10px;
  padding: 5px 5px;
  width: 100%;
}

.staticman-comments .sc-hp {
  font-size: $base-font-size;
  border: 1px solid $background-color;
  background-color: $background-color;
  margin-top: 10px;
  float: right;
  width: 1em;
  height: 1em;
}

.staticman-comments .sc-textarea {
  font-size: $base-font-size;
  border: 1px solid $grey-color-light;
  padding: 5px 5px;
  vertical-align: top;
  height: 8em;
  width: 100%;
}

.staticman-comments button {
  font-size: $base-font-size;
  border: 1px solid $grey-color-light;
  background-color: $grey-color-light;
  color: $grey-color-dark;
  text-align: center;
  margin-top: 10px;
  width: 100px;
  height: $base-font-size * 2;
  -webkit-appearance: none;
}

.staticman-comments button:hover {
  background-color: $brand-color;
  color: $background-color;
  text-decoration: none;
}

.staticman-post-comment {
  margin-top: 15px;
  margin-bottom: 15px;
}

.staticman-post-comment-avatar {
  display: inline-block;
  width: 48px;
  height: 48px;
  vertical-align: middle;
  border-radius: 50%;
}

.staticman-post-comment-info {
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 0px;
  vertical-align: middle;
}

.staticman-submitted-dialog {
  display: none;
  position: fixed;
  background-color: $background-color;
  padding: 25px;
  padding-top: 20%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  a {
    padding: 8px 15px;
    text-decoration: none;

    &,
    &:visited {
      color: white;
      background-color: $brand-color;
    }

    &:hover {
      color: $grey-color;
      background-color: $grey-color-light;
    }
  }
}

.staticman-submitted-dialog:target {
  display: block;
}
