@charset "utf-8";

// Our variables
$base-font-family: "Bitter", "Lucida Sans Unicode", "Lucida Grande", sans-serif;
$monospace-font-family: "Lucida Console", Monaco, monospace;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #bb2525;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// whiteglass also includes a mixin for defining media queries.
// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Custom font links
@font-face {
  font-family: 'Bitter';
  font-style: normal;
  font-weight: 400;
  src: local('Bitter Regular'), local('Bitter-Regular'),
       url('/assets/fonts/bitter-v14-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/assets/fonts/bitter-v14-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// Import partials from the `whiteglass` theme.
@import "themes/whiteglass/assets/sass/whiteglass";

// Figures centered with caption
.post-content figure {
  max-width: 50%;
  display: block;
  margin: 0 auto;
}
.post-content figcaption {
  text-align: center;
}
.post-content figcaption h4 {
  font-size: $base-font-size;
  color: #555;
  font-style: italic;
}

video {
  margin-top: 10px;
  margin-bottom: 10px;
}


// Alignment of figures
.post-content figure.right {
  float: right;
}
.post-content figure.left {
  float: left;
}

// Large figures
.post-content figure.big {
  max-width: 80%;
  width: 80%;
}

// Content links always have an underline
.post-content a {
  text-decoration: underline;
}

// Put "$ cat" before the header text
.headertext::before {
  content: "$ cat ";
}

// A bunch of stuff to get a blinking cursor
.blinking-cursor {
  font-weight: 100;
  color: #2E3D48;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;

  -webkit-animation-iteration-count: 5;
  -moz-animation-iteration-count: 5;
  -ms-animation-iteration-count: 5;
  -o-animation-iteration-count: 5;
  animation-iteration-count: 5;
}

@keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-moz-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-webkit-keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-ms-keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-o-keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
